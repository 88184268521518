import { useUser } from "@auth0/nextjs-auth0/client";

import { useRouter } from "next/router";

import { LoginPage } from "@/components/auth";
import { Loader } from "@/components/common";
import { Layout } from "@/components/layouts";
import { constants, ROUTES } from "@/utils";

const { isAuth0Enabled } = constants;

const NextLoginPage = () => {
  const { user, isLoading } = useUser();

  const router = useRouter();

  if (isAuth0Enabled) {
    if (isLoading) {
      <Loader />;
    }
    if (user) {
      router.push(ROUTES.DASHBOARD);
      return null;
    }
    if (!user) {
      router.replace(ROUTES.AUTH0_LOGIN);
      return null;
    }
  }

  return (
    <Layout title="Sign in">
      <LoginPage />
    </Layout>
  );
};

export default NextLoginPage;
